

(function accordeonListSeo () {
    const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
    const compName = "accordeon-list-seo";
    const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
    const Log = logFactory(`${compName}.js`);

     if (!isCompExists(compName, Log)) {
       return;
     }

 })()